<template>
  <button class='lsc-button lsc-rounded'
          :class="disabled ? 'grey' : 'red'"
          @click.stop="confirmDeleteItem()">
    <icon :name='deleteIcon'></icon>
    {{ deleteLabelTranslated }}
  </button>
</template>

<script>
  import Translate from '@/components/Mixins/Translate';

  export default {
    name: 'delete-button',
    mixins: [Translate],
    props: {
      deleteLabel: {
        // Option to specify a custom label for the button.
        default: 'delete',
        type: String,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        // Should be either 'delete', 'areYouSure', 'deletingDots' or 'deleted',
        // to correspond with phrase library.
        deleteState: 'delete',
        timerId: null,
      };
    },
    computed: {
      deleteIcon() {
        switch (this.deleteState) {
          case 'delete':
            return 'fa-trash';
          case 'areYouSure':
            return 'fa-trash';
          case 'deleted':
            return 'fa-check';
          case 'deletingDots':
            return 'fa-cog fa-spin';
          default: return '';
        }
      },
      deleteLabelTranslated() {
        switch (this.deleteState) {
          case 'areYouSure':
          case 'deleted':
          case 'deletingDots':
            return this.x(this.deleteState);
          default:
            return this.x(this.deleteLabel);
        }
      },
    },
    watch: {
      disabled(newValue) {
        // When disabling the button (maybe after something has been deleted), it should be reset.
        if (newValue === true) {
          this.deleteState = 'delete';
        }
      },
    },
    methods: {
      async confirmDeleteItem() {
        if (this.disabled) return;
        switch (this.deleteState) {
          case 'delete':
            this.deleteState = 'areYouSure';
            this.timerId = setTimeout(() => {
              this.deleteState = 'delete';
            }, 3000);
            break;

          case 'areYouSure':
            clearTimeout(this.timerId);
            this.deleteState = 'deletingDots';
            this.$emit('click');
            break;

          default:
            // Don't do anything if the button is pressed while deleting or after deleting.
            break;
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  a {
    float: left;
    display: inline-block;
    margin: 0 10px 0 0;
    /*color: #ababab;*/
    border: 1px dashed #ababab;
    width: calc(50% - 10px);
    padding: 10px;
    text-align: center;

    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;

    background: #e74c3c;
    color: #ffffff;

    i {
      font-size: 32px;
    }
  }

  a.selected {
    border: 0;
    border-bottom: 3px solid rgba(0, 0, 0, 0.3);
  }

</style>
