export default class ImageSwipe {
  constructor(elm) {
    this.initPosX = 0;
    this.cDiff = false;
    this.cPosX = 0;
    this.cFocus = 1;
    this.cHeight = null;
    this.ElmAmount = $(`${elm} .swipe_item`).length;
    this.ElmWidth = $(this.elm).width();
    this.elm = elm;
    this.initialImage = -1; // Might be set if we request to go to an image before stuff is loaded.
    // In that case, this will be set, and the swipe will happen after loading has completed.
    this.initialized = false;
    this.retryCount = 0; // Retry count for waiting for image to be loaded.

    this.bindSwipe(elm);

    this.getHeight();
  }

  getHeight() {
    // The actual image element might not be the same as the swipe item: the swipe item
    // might contain other html. Here we need to check if the actual image is loaded,
    // and we hope that the swipe item only contains a single image element.
    const imageElement = document.querySelector(`${this.elm} img:nth-child(1)`);
    if (!imageElement) {
      console.log('Image null.');
      this.cHeight = null;
      this.retryGetHeight();
      return;
    }

    const loaded = imageElement.complete;
    if (!loaded) {
      console.log('Image not loaded yet.');
      this.cHeight = null;
      this.retryGetHeight();
    } else {
      this.cHeight = imageElement.offsetHeight;
      console.log('this.cHeight: ', this.cHeight);
      this.retryCount = 0;
      this.initContainer();
    }
  }

  retryGetHeight() {
    setTimeout(() => {
      this.retryCount += 1;
      if (this.retryCount < 10) {
        this.getHeight();
      }
    }, 500);
  }

  initContainer() {
    /** Update container Size * */

    $(this.elm).css('width', `${this.ElmAmount * 100}%`);
    $(`${this.elm} .swipe_item`).css('width', `${100 / this.ElmAmount}%`);
    $(this.elm).css('height', `${this.cHeight}px`);
    $(`${this.elm} .swipe_item`).css('float', 'left');
    this.initialized = true;
    if (this.initialImage > 0) {
      this.goToImage(this.initialImage);
      this.initialImage = -1;
    }
  }

  bindSwipe() {
    if (this.ElmAmount > 1) {
      $(this.elm).on('touchstart', (event) => { this.touch(event); });
      $(this.elm).on('touchmove', (event) => { this.move(event); });
      $(this.elm).on('touchend', (event) => { this.release(event); });
    }
  }

  touch(event) {
    this.ElmWidth = $(this.elm).width();

    this.initPosX = parseInt($(this.elm).css('margin-left'), 10);

    const touch = event.originalEvent.touches[0] || event.originalEvent.changedTouches[0];
    this.cDiff = touch.pageX;
  }

  move(event) {
    const touch = event.originalEvent.touches[0] || event.originalEvent.changedTouches[0];
    const x = touch.pageX;

    this.cPosX = x + this.initPosX;
    this.cPosX -= this.cDiff;

    if (this.cPosX > 0) { this.cPosX = 0; }
    if (this.cPosX < -(this.ElmWidth - (this.ElmWidth / this.ElmAmount))) {
      this.cPosX = -(this.ElmWidth - (this.ElmWidth / this.ElmAmount));
    }

    $(this.elm).css('margin-left', `${this.cPosX}px`);
  }

  release() {
    const endPos = Math.abs(this.cPosX);
    let inPercent = endPos / (this.ElmWidth / 100);

    this.cFocus = 1;

    /** 11-01-18 => Lausen * */
    const imgWidth = 100 / parseInt(this.ElmAmount, 10);

    console.log('imgWidth: ', imgWidth);
    console.log('inPercent: ', inPercent);

    if (this.ElmAmount > 1) {
      if (inPercent < (imgWidth / 2)) {
        this.cFocus = 1;
      } else {
        inPercent += imgWidth;
        this.cFocus = Math.round(inPercent / imgWidth);
      }
    }

    console.log('this.cFocus: ', this.cFocus);

    const p = $(`${this.elm} .swipe_item:nth-child(${this.cFocus})`);
    const position = p.position();

    this.cHeight = $(`${this.elm} .swipe_item:nth-child(${this.cFocus})`).height();

    $(this.elm).animate({
      marginLeft: `-=${position.left}px`,
      height: `${this.cHeight}px`,
    }, 200);
  }

  goToImage(imageIndex) {
    if (!this.initialized) {
      this.initialImage = imageIndex;
      return;
    }
    this.ElmWidth = $(this.elm).width();

    this.cPosX = -(this.ElmWidth / this.ElmAmount) * (imageIndex - 1);

    // console.log('image_num: ', image_num);
    // console.log("ElmWidth: ",this.ElmWidth);
    // console.log("ElmAmount: ", this.ElmAmount);
    // console.log("cPosX: ", this.cPosX);
    // this.cPosX = 50;
    this.release();
  }
}
